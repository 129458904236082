<template>
  <div class="regist">
    <login-wrap style="width: 380px;margin-top: 25px;padding: 30px;">
      <!-- 注册 -->
      <el-form status-icon ref="regist" :model="regist" :rules="registRules" size="medium" class="regist-form"
        hide-required-asterisk>
        <div class="title">商家注册</div>
        <el-form-item prop="name">
          <el-input v-model.trim="regist.name" placeholder="请输入商户名称" prefix-icon="el-icon-s-shop" @blur="isRegist"
            @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
        </el-form-item>
        <el-form-item prop="userName">
          <el-input v-model.trim="regist.userName" placeholder="请输入登录账号(4-16位)" prefix-icon="el-icon-user"
            @blur="isRegist" @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" show-password v-model.trim="regist.password" placeholder="请输入登录密码(不少于8位)"
            prefix-icon="el-icon-lock" @blur="isRegist" @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
        </el-form-item>
        <el-form-item prop="rePassword">
          <el-input type="password" show-password v-model.trim="regist.rePassword" placeholder="请再次确认登陆密码"
            prefix-icon="el-icon-lock" @blur="isRegist" @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="wechat">
          <el-input
            v-model.trim="regist.wechat"
            placeholder="请输入微信号"
            prefix-icon="el-icon-s-comment"
            @blur="isRegist"
            @keyup.native="openFlag"
            @keydown.native="closeFlag"
          ></el-input>
        </el-form-item> -->
        <!--        <el-form-item prop="area">-->
        <!--          <el-cascader-->
        <!--            placeholder="请选择经营地区"-->
        <!--            :options="options"-->
        <!--            v-model="selectedOptions"-->
        <!--            @change="handleChange"-->
        <!--          ></el-cascader>-->
        <!--        </el-form-item>-->
        <!-- <el-form-item prop="mobile">
          <el-input
            v-model.number="regist.mobile"
            placeholder="请输入您的手机号"
            prefix-icon="el-icon-mobile"
            @blur="isRegist"
            @keyup.native="openFlag"
            @keydown.native="closeFlag"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item class="code" prop="code">
          <el-input v-model.trim="regist.code" placeholder="短信验证码" prefix-icon="el-icon-message"
            @blur="isRegist"
            @keyup.native="openFlag"
            @keydown.native="closeFlag"
          ></el-input>
          <el-button
            type="primary"
            class="code-btn"
            :disabled="!!seconds || !regist.mobile"
            @click="onClickSendSmsButton"
          >{{codeBtn}}</el-button>
        </el-form-item> -->
        <!--
        <el-form-item>
          <el-input v-model="regist.invitationCode" :disabled="hasUrlCode" @blur="isRegist" @keyup.native="openFlag" @keydown.native="closeFlag">
            <template slot="prepend">邀请码</template>
</el-input>
</el-form-item>
-->
      </el-form>
      <div class="registBtn">
        <el-button class="submit-btn" @click="toRegist" :disabled="isOK">{{ !isLoading ? '注&nbsp;册' :
        '注&nbsp;册&nbsp;中' }}<span v-show="isLoading"><i class="dot">•••</i></span></el-button>
      </div>
    </login-wrap>
  </div>
</template>

<script>
import loginWrap from './components/login-wrap';
import code from './mixins';
import service from './api';
import gt from '@/mixins/geetest';
import { regionData, CodeToText } from 'element-china-area-data';
var timer;
var flag = 0;
export default {
  mixins: [code],
  data() {
    var checkPsd = (rule, value, callback) => {
      if (this.regist.rePassword !== '') {
        this.$refs.regist.validateField("rePassword");
      }
      callback();
    };
    var checkRepsd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regist.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      registRules: {
        // mobile: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'blur' }
        // ],
        // code: [{ required: true, message: '请填写验证码', trigger: 'blur' }],
        name: [
          { required: true, message: '请填写商户名称', trigger: 'blur' },
          { pattern: /^[a-zA-z0-9\u4e00-\u9fa5]{1,7}$/, message: '1-7位字符，由数字、字母、中文组成', trigger: 'blur' }
        ],
        // personName: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
        // wechat: [{ required: true, message: '请填写微信号', trigger: 'blur' }],
        // area: [{ required: true, message: '请选择经营地区', trigger: 'blur' }]
        userName: [
          { required: true, message: '请填写账号', trigger: 'blur' },
          {
            pattern: /^[a-zA-z0-9]\w{3,15}$/,
            message: "4-16位字符，可含数字、字母、下划线",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { pattern: /^[a-zA-z0-9]{8,}$/, message: '密码不能少于8位字符', trigger: 'blur' },
          { validator: checkPsd, trigger: "blur" },
        ],
        rePassword: [{ validator: checkRepsd, trigger: "blur" }]
      },
      // regist: { personName: '', name: '', wechat: '', mobile: '', code: '', area: [], invitationCode: '', userName:'', password:''},
      regist: { name: '', wechat: '', mobile: '', code: '', invitationCode: '', userName: '', password: '', rePassword: '' },
      seconds: 0,
      options: regionData,
      selectedOptions: [],
      hasUrlCode: false,
      canRegist: true,
      isLoading: false, // 注册loading
      isOK: true,  //不能点击注册
      captcha: false,
    };
  },
  components: {
    loginWrap
  },
  methods: {
    initGt() {
      return gt.register().then(captcha => {
        this.captcha = captcha;
        return captcha;
      })
    },
    onClickSendSmsButton() {
      this.captcha.onReady(function () {
      }).onSuccess(() => {
        let result = this.captcha.getValidate();
        if (!result)
          return;
        // 登录
        this.getCode('regist', result);
      });
      this.captcha.verify();
    },
    handleChange(val) {
      let result = [];
      val.forEach(item => result.push(CodeToText[item]));
      this.regist.area = result;
    },
    // 注册
    toRegist() {
      let check = false;
      this.$refs['regist'].validate(res => (check = res));
      if (!check) return;
      clearTimeout(this.registTimer);
      if (!this.isLoading) {
        this.isLoading = true;
        this.isOK = true;
        this.registTimer = setTimeout(() => this.gotoRegist(), 500)
      }
    },
    gotoRegist() {
      service.regist(this.regist).then(res => {
        this.isLoading = false;
        this.$notify({ message: '注册成功', type: 'success' });
        this.$router.push({ path: '/login' });
      }).catch(() => {
        this.isLoading = false;
        this.isOK = false;
        this.initGt();
      });
    },
    // 校验链接是否有效
    checkCode(code) {
      return service.checkCode({ code }).then(res => {
        let { canRegist = true, errMsg = '' } = res;
        this.canRegist = canRegist;
        !canRegist && this.$notify({ message: errMsg, type: 'error', offset: 400 });
      });
    },
    //是否能注册
    isRegist(inputName) {
      if (flag == 1) {
        let check = false;
        this.$refs['regist'].validate(res => (check = res));
        if (!check) this.$refs.regist.clearValidate(); // 先清空校验
        for (let k in this.regist) {
          if (this.regist[k] !== '') {
            this.$refs['regist'].validateField(k); // 对值不为空的表单再次校验
          }
        }
        if (check && this.regist.invitationCode) {
          this.isOK = false;
        } else {
          this.isOK = true;
        }
      }
      clearTimeout(timer);
      flag = 0;
    },
    openFlag() {
      let self = this;
      timer = setTimeout(function () {
        flag = 1;
        self.isRegist();
      }, 500);
    },
    closeFlag() {
      clearTimeout(timer);// 取消定时器
      flag = 0;
    }
  },
  mounted() {
    this.initGt();
  },
  created() {
    let { c = '' } = this.$route.query;
    this.hasUrlCode = !!c;
    this.regist.invitationCode = c;
    service.pv({ code: c });
    service.statistics({ code: c });
    this.checkCode(c);
  },
  beforeDestroy() {
    clearTimeout(this.registTimer);
  }
};
</script>

<style lang="scss" scoped>
.regist {
  text-align: center;

  .wrap-body {
    // height: 550px !important;
  }

  .wrap-body-left {
    // background-size:cover;
    background-color: #f56c6c;
  }

  .innmel{
    height: 45px;
    line-height: 45px;
  }

  &-form {
    // width: 320px;
    margin: 16px auto 0 auto;

    .el-form-item {
      margin-bottom: 16px;
      height: 50px;
      line-height: 50px;
    }

    .title {
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 30px;
    }

    .el-cascader {
      width: 320px;
    }

    .code {
      .el-input {
        width: 200px;
        margin-right: 5px;
      }

      &-btn {
        width: 112px;
      }
    }
  }

  .registBtn {
    .submit-btn.el-button {
      width: 320px;
      font-size: 18px;
      color: #ffffff;
      background-color: #f56c6c;
      border-color: #f56c6c;
      //letter-spacing: 10px;
    }

    .el-button.is-disabled {
      background-color: #e2e2e2;
      border-color: #e2e2e2;

      &:hover {
        background-color: #e2e2e2;
        border-color: #e2e2e2;
      }
    }

    span {
      padding: 0;
      display: inline-block;
      width: 1.5rem;

      .dot {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 2px;
        //width: 2em;
        vertical-align: bottom;
        overflow: hidden;
        animation: dot 1s infinite step-start;
      }

      @keyframes dot {
        0% {
          width: 0;
          margin-right: 1.5em;
        }

        33% {
          width: .5em;
          margin-right: 1em;
        }

        66% {
          width: 1em;
          margin-right: .5em;
        }

        100% {
          width: 1.5em;
          margin-right: 0;
        }
      }
    }
  }

}

@media screen and (max-width: 480px) {
  .regist {
    min-height: 20rem;
  }

  .geetest_holder.geetest_wind {
    min-width: 100% !important;
  }

  .registBtn {
    width: 90%;
    margin: 0 auto 2rem;

    .submit-btn {
      width: 100% !important;
    }
  }

  .el-form {
    padding: 0 1rem;
  }

  .regist-form .el-form-item {
    margin-bottom: 0.5rem;
  }

  .regist-form .code {
    .el-input {
      width: 65%;
      float: left;

    }

    .el-button {
      width: 30% !important;
      float: right;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.el-input--medium .el-input__inner {
  height: 45px;
  line-height: 45px;
}
</style>
